import Submit from "components/submit";

import clsx from "clsx";
import { CSSTransition } from "react-transition-group";

import { useState } from "react";

interface DialogProps {
  title: string;
  children?: React.ReactNode;
  id?: string;
  type?: string;
  confirmText?: string;
  cancelText?: string;
  disableConfirm?: boolean;
  noFade?: boolean;
  bottom?: boolean;
  className?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export default function Dialog(props: DialogProps): JSX.Element {
  const { id, type, title, confirmText, cancelText, disableConfirm } = props;
  const { noFade, bottom, className } = props;
  const { onConfirm, onCancel } = props;

  const [loading, setLoading] = useState(false);
  const [hide, setHide] = useState(false);

  function handleConfirm() {
    if (!onConfirm) return handleCancel();

    setLoading(true);
    onConfirm();
  }

  function handleCancel() {
    setHide(true);
    setTimeout(onCancel, 500);
  }

  function borderColor() {
    const colors = {
      warning: "border-t-primary-400",
      success: "border-t-success",
      error: "border-t-error"
    };
    return colors[type || "warning"];
  }

  return (
    <>
      <CSSTransition appear={true} in={!hide} classNames="fade" timeout={100}>
        <div
          className={clsx(
            "dialog-bg fixed z-40 left-0 top-0 w-full h-full bg-black bg-opacity-20",
            { hidden: noFade }
          )}
        ></div>
      </CSSTransition>
      <CSSTransition
        appear={true}
        in={!hide}
        classNames="show-up"
        timeout={400}
      >
        <div className="dialog-wrapper fixed z-50 left-0 top-0 w-full h-full pointer-events-none">
          <div
            id={id}
            className={clsx(
              "dialog w-[460px] md:ml-[120px] absolute z-50 bottom-0 px-5 py-4 max-w-full bg-white border-t-2 shadow-xl pointer-events-auto md:bottom-auto md:left-1/2 md:top-1/2 md:rounded-b-lg md:transform md:-translate-x-1/2 md:-translate-y-1/2",
              borderColor(),
              {
                "md:!w-[720px] md:!translate-y-0 md:!top-auto md:bottom-[30px]":
                  bottom
              },
              className
            )}
          >
            <span className="block mb-3 text-lg font-semibold">{title}</span>

            <div className="content">{props.children}</div>

            <div
              className={clsx("actions flex flex-col mt-5 space-y-3", {
                "justify-between": !!onConfirm,
                "justify-center": !onConfirm
              })}
            >
              <Submit
                onClick={handleConfirm}
                loading={loading}
                disabled={disableConfirm}
                className="btn btn-primary !whitespace-normal w-full"
              >
                {confirmText}
              </Submit>

              {onConfirm && (
                <button
                  className="btn btn-secondary !whitespace-normal mb-0 w-full"
                  onClick={handleCancel}
                  disabled={loading}
                >
                  {cancelText || "Cancelar"}
                </button>
              )}
            </div>
          </div>

          <style jsx>{`
            .content :global(.dialog-text) {
              @apply text-emphasis-medium;
            }
          `}</style>
        </div>
      </CSSTransition>
    </>
  );
}
